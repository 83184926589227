import { defineStore } from "pinia";
import { Store, productTypeMapper } from "@/utils/constants";
import { axiosInstance } from "@/utils/api/axios_instance";
import { API } from "@/utils/api/api_paths";
import camelcaseKeys from "camelcase-keys";
import type { AvailableProduct } from "@/types/apiTypes";
import { usePaymentStore } from "@/stores/payment";
import { CAMPAIGN } from "@/utils/campaign-trackers";

export enum DiscountType {
  PERCENTAGE = "percentage",
  FIXED_AMOUNT = "fixed_amount",
}

export const useDiscountStore = defineStore({
  id: "discount",
  state: () => ({
    code: "",
    value: 0,
    products: [],
    valueType: DiscountType.FIXED_AMOUNT,
    performedValidation: false,
    isValidating: false,
    error: false,
    referral: {
      code: "",
      error: false,
      isValidating: false,
      performedValidation: false,
      associatedDiscountCode: "",
    },
  }),
  getters: {
    isDiscountFullValue(): boolean {
      const paymentStore = usePaymentStore();
      return paymentStore.selectedProduct!.price - this.getDiscountVal(paymentStore.selectedProduct!) <= 0;
    },
    hasActiveVoucher(): boolean {
      return !!this.code && !!this.value && !this.error && this.performedValidation;
    },
    hasActiveReferral(): boolean {
      return !!this.referral.code && !this.referral.error && this.referral.performedValidation;
    },
    getDiscountVal: (state) => {
      return (product: AvailableProduct): number => {
        if (state.valueType === DiscountType.FIXED_AMOUNT) return state.value;
        return parseFloat(((product.price * state.value) / 100).toFixed(2)); //* only applies to 1 month
      };
    },
  },
  actions: {
    async validate(value: string, store: Store = Store.USA) {
      const paymentStore = usePaymentStore();

      this.resetDiscount();
      this.isValidating = true;
      this.code = value;

      try {
        const url = `${API.DISCOUNT_CODE.replace("{CODE}", encodeURIComponent(value))
          .replace("{PRODUCT_ID}", paymentStore.selectedProductId.toString())
          .replace("{STORE}", store)}`;
        const data = await axiosInstance.get(url);

        const camelData = camelcaseKeys(data, { deep: true });
        const processedData = this.createDiscount(camelData.data);
        if (this.isDataValid(processedData)) this.applyVoucher(processedData);
      } catch (err) {
        console.error("Error validating discount code, ", err);
        this.error = true;
      } finally {
        this.isValidating = false;
      }
    },
    async validateReferral(value: string) {
      const paymentStore = usePaymentStore();

      this.resetReferral();
      this.referral.isValidating = true;
      this.referral.code = value;

      try {
        const res = await axiosInstance.get(
          API.CHECK_RAF_CODE.replace("{CODE}", value).replace(
            "{PRODUCT}",
            productTypeMapper[paymentStore.selectedProductCategory]
          )
        );
        const camelData = camelcaseKeys(res.data, { deep: true });

        const associatedDiscountCode = camelData.data?.data[0]?.properties?.discountCode;
        this.applyReferral(value, associatedDiscountCode);
        if (associatedDiscountCode) this.handleAssociatedDiscountFromReferal();
      } catch (err) {
        console.error("Error validating referral code, ", err);
        this.referral.error = true;
      } finally {
        this.referral.isValidating = false;
      }
    },
    createDiscount(data: any) {
      return {
        code: data.code,
        valueType: data.priceRule.valueType,
        value: data.priceRule.value,
        startsAt: data.priceRule.startsAt,
        products: data.products,
      };
    },
    isDataValid(voucherResult: any) {
      return (
        (voucherResult.valueType === DiscountType.FIXED_AMOUNT ||
          voucherResult.valueType === DiscountType.PERCENTAGE) &&
        (voucherResult.startsAt ? new Date(voucherResult.startsAt).getTime() <= new Date().getTime() : false)
      );
    },
    applyVoucher(discount: any) {
      this.performedValidation = true;
      this.error = false;
      this.value = Math.abs(parseInt(discount.value));
      this.code = discount.code;
      this.valueType = discount.valueType;
      if (discount.products) this.products = discount.products;
    },
    applyReferral(code: string, discountCode: string = "") {
      this.referral.code = code;
      this.referral.error = false;
      this.referral.performedValidation = true;
      this.referral.isValidating = false;
      if (discountCode) this.referral.associatedDiscountCode = discountCode;
    },
    resetDiscount(): void {
      this.code = "";
      this.value = 0;
      this.products = [];
      this.valueType = DiscountType.FIXED_AMOUNT;
      this.performedValidation = false;
      this.isValidating = false;
      this.error = false;
    },
    resetReferral(): void {
      this.referral.code = "";
      this.referral.error = false;
      this.referral.isValidating = false;
      this.referral.performedValidation = false;
      this.referral.associatedDiscountCode = "";
    },
    handleAssociatedDiscountFromReferal(): void {
      if (CAMPAIGN.active && CAMPAIGN.discount.code) return; // * Discount codes from CAMPAIGNS are more important
      if (this.router.currentRoute.value.query.discount && this.code === this.router.currentRoute.value.query.discount)
        return; // * Discount codes from URLs are more important

      this.resetDiscount();
      this.validate(this.referral.associatedDiscountCode);
    },
  },
});
