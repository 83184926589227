import type { CountryCode, Store, USState } from "@/utils/constants";

export type PharmacologicalGroup = "diuretic" | "statins" | "blood-thinner";

export interface Medication {
  pharmacologicalGroup: PharmacologicalGroup;
  medicationName: string;
}

export interface Ingredient {
  ingredient: {
    name: string;
    healthGoals: string[];
    componentSources: string;
    foodSources: string;
  };
  amountPerServing: number;
  unit: string;
  nrvPercent: string;
}

export interface Supplement {
  supplement: {
    name: string;
  };
  dosage: number;
  basicDose: number;
  finalDoes: number;
}

export interface SupplementFormula {
  id: number;
  status: string;
  createdAt: string;
  supplements: Supplement[];
  ingredients: Ingredient[];
  formulaDraftUuid: string;
}

export enum ShippingType {
  STANDARD = "standard",
  PREMIUM = "premium",
}

export enum ProductCategory {
  GO_SINGLE_PURCHASE = "go_single_purchase",
  GO_SUBSCRIPTION = "go_subscription",
}

export interface ShippingZone {
  id: number;
  deliveryPrice: number;
  shippingType: ShippingType;
  shopifyShippingTitle: string;
  slaInDays: string;
}

export enum Currency {
  EUR = "EUR",
  GBP = "GBP",
  AED = "AED",
  USD = "USD",
}

export interface AvailableProduct {
  id: number;
  key: string;
  name: string;
  description: string;
  category: ProductCategory;
  quantity: number;
  price: number;
  currency: Currency;
  productFamily: string;
  shippingZones: ShippingZone[];
}

export interface Profile {
  gender: "1" | "2" | "3";
  dateOfBirth: string;
  height: number;
  weight: number;
  heightUnit: "cm" | "inches";
  weightUnit: "kg" | "lbs";
  firstName: string;
  lastName: string;
  streetAddress: string;
  zip: string;
  city: string;
  addressDetails?: string;
  state?: USState["name"];
  stateCode?: USState["code"];
  deliveryAddress: {
    streetAddress: string;
    zip: string;
    city: string;
    countryCode: string;
    addressDetails?: string;
    state?: USState["name"];
    stateCode?: USState["code"];
    latitude?: number;
    longitude?: number;
  };
  phone: string;
  email: string;
  countryCode?: string;
}

export interface OrderSubmit {
  profile: Profile;
  discountCode?: string;
  referralCode?: string;
  bioniqReferralCode?: string;
  funnel: string;
  storeCountry: Store;
  storeBrand: "bioniq";
  shippingZoneId: number;
  productTypeId: number;
  bioniqGoQuestionnaire: { questionKey: string; answerKeys: string[] }[];
  fullSupplementFormula: { status: string; supplements: Supplement[]; ingredients: Ingredient[] };
}

export interface ValidateAddressPayload {
  provider: "fedex";
  additionalParams?: {};
  countryCode: CountryCode;
  addressLine: string;
  postalCode: string;
  state: USState["code"];
  city: string;
}
