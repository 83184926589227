<script setup lang="ts">
import { computed } from "vue";
import TextInputBox from "@/components/ui/TextInputBox.vue";
import { usePhraseTranslationShortcuts } from "@/composables/usePhraseTranslationShortcuts";
import { LifestyleQuestionKey, type QuestionKey } from "@/types/questions";
import { transliterate } from "transliteration";

const QuestionsToLatinize = [LifestyleQuestionKey.FULL_NAME, LifestyleQuestionKey.NAME_BOTTLE];

const { phraseNrByRouteName } = usePhraseTranslationShortcuts();

const props = defineProps<{
  value: string;
  errored: boolean;
  usaLabel?: string;
  activeQuestionKey?: QuestionKey;
}>();

const emit = defineEmits<{
  (e: "change", value: string): void;
}>();

const emitChange = (val: string) => {
  // @ts-ignore
  const newVal = QuestionsToLatinize.includes(props.activeQuestionKey) ? transliterate(val) : val;
  emit("change", newVal);
};

const label = computed<string>(() => {
  if (props.usaLabel) return `QUESTION_${phraseNrByRouteName.value}.LABEL_US.${props.usaLabel}`;
  return `QUESTION_${phraseNrByRouteName.value}.LABEL`;
});
</script>

<template>
  <div class="question-text-input">
    <TextInputBox
      :inputName="`question${phraseNrByRouteName}`"
      :labelText="$t(label)"
      :value="props.value"
      :errored="errored"
      @update="emitChange"
      :autofocusOn="true"
      :transliterate="activeQuestionKey === LifestyleQuestionKey.FULL_NAME"
    />
  </div>
</template>

<style scoped lang="pcss"></style>
