import axios from "axios";
import { API } from "@/utils/api/api_paths";
import { getFirstLastName, getUTMFromURLOrCookie } from "@/utils/functions";
import { useMainStore } from "@/stores/main";
import { usePaymentStore } from "@/stores/payment";
import { useLifestyleStore } from "@/stores/lifestyle";
import { useSupplementsStore } from "@/stores/supplements";
import { useQuestionsStore } from "@/stores/questions";
import { useRoute } from "vue-router";
import { LifestyleQuestionKey, type Question } from "@/types/questions";
import { FormulaPresentation } from "@/utils/constants";

enum GOCheckoutVersion {
  DEFAULT = "default",
}

type Shipping_Validated_By = "api_validated" | "customer_validated" | "region_not_validated";

export function useCrm() {
  const paymentStore = usePaymentStore();
  const questionStore = useQuestionsStore();
  const lifestyleStore = useLifestyleStore();
  const supplementsStore = useSupplementsStore();
  const mainStore = useMainStore();
  const qStore = useQuestionsStore();
  const route = useRoute();

  const computeShipping = (): Shipping_Validated_By => {
    if (!paymentStore.isSelectedCountryCodeShippableByFedex) return "region_not_validated";
    if (paymentStore.addressCorrectConsentSelected) return "customer_validated";
    if (!paymentStore.addressCorrectConsentSelected) return "api_validated";
    return "" as never;
  };

  const createContact = (): Promise<void | false> => {
    const body = {
      ...getFirstLastName(qStore.lifestyleStore.fullName),
      email: lifestyleStore.email,
      formula: JSON.stringify(supplementsStore.supplementsFormula),
      store: mainStore.store,
      locale: mainStore.locale,
      consent: lifestyleStore.marketingConsentSelected,
      smsConsent: lifestyleStore.smsConsentSelected,
      answers: JSON.stringify(questionStore.allAnswers),
      answersLabels: questionStore.readableAnswers,
      utmParameters: getUTMFromURLOrCookie(route.query),
      go_checkout_version: mainStore.checkoutVersion || GOCheckoutVersion.DEFAULT,
      preferred_language: mainStore.locale,
      go___ip_address: mainStore.ipLocCountry.ip,
      go___ip_country: mainStore.ipLocCountry.name,
      formula_draft_uuid: supplementsStore.formulaDraftUuid,
      name_on_jar: lifestyleStore.bottleName,
      checkout_shopify: mainStore.checkoutShopifyVersion,
      formula_presentation: mainStore.formulaPresentation || FormulaPresentation.Control,
    };
    return axios
      .post(API.CONTACT, body, { withCredentials: true })
      .then(() => saveHubspotContactEmail(lifestyleStore.email))
      .catch((err) => {
        console.error("Error on creating contact, ", err);
        return false;
      });
  };

  const updateContact = (hubspotEmail: string) => {
    const consent = lifestyleStore.marketingConsentSelected || paymentStore.marketingConsentSelected;
    const smsConsent = lifestyleStore.smsConsentSelected;
    const { firstName, lastName, email, phoneNumber } = paymentStore.customerInformation;
    const customerInfo = {
      firstName,
      lastName,
      email,
      phoneNumber: phoneNumber.e164,
      store: mainStore.store,
      consent,
      smsConsent,
      go_checkout_version: mainStore.checkoutVersion || GOCheckoutVersion.DEFAULT,
      go_checkout_started: true,
      preferred_language: mainStore.locale,
      go___ip_address: mainStore.ipLocCountry.ip,
      go___ip_country: mainStore.ipLocCountry.name,
      shipping_validated_by: computeShipping(),
      formula_draft_uuid: supplementsStore.formulaDraftUuid,
      name_on_jar: lifestyleStore.bottleName,
      checkout_shopify: mainStore.checkoutShopifyVersion,
      formula_presentation: mainStore.formulaPresentation || FormulaPresentation.Control,
    };
    return axios
      .put(`${API.CONTACT}/${hubspotEmail}`, { ...customerInfo })
      .then(() => saveHubspotContactEmail(email))
      .catch((err) => {
        console.error("Error on updating contact, ", err);
        return false;
      });
  };

  const updateContactWithSurveyQuestion = (hubspotEmail: string, val: string) => {
    const extraInfo = { how_did_you_hear_us_from_go_survey: val };

    return axios
      .put(`${API.CONTACT}/${hubspotEmail}`, { ...extraInfo })
      .then((response) => [null, response])
      .catch((err) => {
        console.error(`Error updating Hubspot Contact with 'How you heard about Bioniq' question, ${err}`);
        return [err, null];
      });
  };

  const getContactById = (id: string) => {
    return axios
      .get(`${API.CONTACT}/${id}`)
      .then((response) => {
        return {
          name: `${response.data.firstname} ${response.data.lastname}`,
          email: response.data.email,
          formula: response.data.formula_content ? JSON.parse(response.data.formula_content) : "",
          phoneNumber: response.data.phone || "",
          marketingConsent: response.data.ab_marketing_consent === "true",
          smsConsent: response.data.agree_to_sms === "true",
          answers: response.data.questionnaire_answers ? JSON.parse(response.data.questionnaire_answers) : "",
          checkoutVersion:
            response.data.go_checkout_version === GOCheckoutVersion.DEFAULT ? "" : response.data.go_checkout_version,
          goIpCountry: response.data.go___ip_country,
          goIpAddress: response.data.go___ip_address,
          formulaDraftUuid: response.data.formula_draft_uuid,
          nameOnJar: response.data.name_on_jar,
          checkoutShopify: response.data.checkout_shopify,
          formulaPresentation: response.data.formula_presentation || FormulaPresentation.Control,
        };
      })
      .catch((err) => {
        console.error("Error on the GET contact request, ", err);
        return false;
      });
  };

  const loadDataFromContact = async (toRoute: any) => {
    const contactId = toRoute.query.contactId as string;
    const contact: any = await getContactById(contactId);
    if (!contact || !contact.formula) return false;

    localStorage.clear();

    const qStore = useQuestionsStore();
    const paymentStore = usePaymentStore();

    mainStore.setCheckoutVersion(contact.checkoutVersion);
    mainStore.setCheckoutShopifyVersion(contact.checkoutShopify);
    mainStore.setFormulaPresentation(contact.formulaPresentation);

    supplementsStore.setFormula(contact.formula);
    if (contact.answers) qStore.updateAnswersForAllStores(contact.answers);
    qStore.lifestyleStore.updateName(contact.name);
    qStore.lifestyleStore.updateEmail(contact.email);
    qStore.lifestyleStore.updateNameOnJar(contact.nameOnJar);
    qStore.lifestyleStore.marketingConsentSelected = contact.marketingConsent;
    qStore.lifestyleStore.smsConsentSelected = contact.smsConsent;
    paymentStore.updatePhoneNumber(contact.phoneNumber);
    saveHubspotContactEmail(contact.email);
    return true;
  };

  const saveHubspotContactEmail = (email: string) => {
    const mainStore = useMainStore();
    mainStore.setHubspotContactEmail(email);
  };

  const shouldLoadContact = (toRoute: any) => {
    return !!toRoute.query.contactId;
  };

  const isConsentSelectedIfEmailQuestion = (activeQuestion: Question) => {
    const questStore = useQuestionsStore();
    if (activeQuestion.key === LifestyleQuestionKey.EMAIL) return questStore.lifestyleStore.marketingConsentSelected;
    return true;
  };

  return {
    createContact,
    updateContact,
    loadDataFromContact,
    saveHubspotContactEmail,
    shouldLoadContact,
    isConsentSelectedIfEmailQuestion,
    updateContactWithSurveyQuestion,
  };
}
