import MainView from "@/views/MainView.vue";
import {
  checkForSupplementsFormula,
  informationMustBeFilled,
  resetStoreToIPStore,
  redirectToOrderRecipeIfNotAllowed,
  reloadIfFromThankYou,
  loadContactFromCrm,
  checkThankYouQueryParams,
  checkThankYouLocalStorageData,
  saveQuestionnaireToLS,
} from "@/router/guards";
import {
  SectionKey,
  NoneQuestionKey,
  PaymentKey,
  type RouteKey,
  OtherRouteKey,
  type QuestionKey,
} from "@/types/questions";
import {
  initializeLanguage,
  setIPLocationCountry,
  initializeCountryCodes,
  adaptQuestionsType,
  setDataFromStorage,
  setHealthGoalsFromUrl,
} from "@/router/guards/location";
import {
  initializeCheckoutVersion,
  initializeCheckoutShopifyVersion,
  initializeFormulaPresentation,
} from "@/router/guards/ab_versions";
import { LocalStorageKeys, sectionToNumberStep } from "@/utils/constants";
import QuestionActive from "@/components/QuestionActive.vue";
import { setDiscount, setReferral, setCampaignDiscount, resetDiscount, resetReferral } from "@/router/guards/discount";
import { initializeGoogleServices } from "@/services/GooglePredictions";

export enum RoutePath {
  SECTION_INTRO = "section-intro",
  SECTION_BASICS = "section-basics",
  SECTION_HEALTH = "section-health",
  SECTION_LIFESTYLE = "section-lifestyle",
  Q_B_DOB = "date-of-birth",
  Q_B_UNDER_18 = "under-18",
  Q_B_HEIGHT = "height",
  Q_B_WEIGHT = "weight",
  Q_B_PRE_POST_NATAL_HEALTH = "pre-post-natal-health",
  Q_B_PREGNANT_OR_PLANNING = "pregnant-or-planning",
  Q_B_WHICH_BEST_DESCRIBES = "which-best-describes",
  Q_B_PREGNANCY_WEEKS = "pregnancy-weeks",
  Q_B_ASK_REPRODUCTIVE = "ask-reproductive",
  Q_B_MENSTRUAL = "menstrual",
  Q_B_PREMESNSTRUAL = "premenstrual",
  Q_B_MENOPAUSE = "menopause",
  Q_H_GENDER = "gender",
  Q_H_CONCERNS = "concerns",
  Q_H_BRAIN_FOG = "brain-fog",
  Q_H_COGNITIVE = "cognitive",
  Q_H_BLOOD_PRESSURE = "blood-pressure",
  Q_H_EDEMA = "edema",
  Q_H_ANXIETY = "anxiety",
  Q_H_MOOD = "mood",
  Q_H_DEPRESSION = "depression",
  Q_H_ASLEEP = "asleep",
  Q_H_PANIC = "panic",
  Q_H_FITNESS_GOAL = "fitness-goal",
  Q_H_PHYSICAL_ACTIVITY = "physical-activity",
  Q_H_MENTAL_WORN_OUT = "mental-worn-out",
  Q_H_JOINT_PAIN = "joint-pain",
  Q_H_BROKEN = "broken",
  Q_H_SKIN_TYPE = "skin-type",
  Q_H_SKIN_ISSUES = "skin-issues",
  Q_H_SKIN_BREAKOUTS = "skin-breakouts",
  Q_H_SKIN_FLAKY = "skin-flaky",
  Q_H_HAIR = "hair",
  Q_H_FINGERNAILS = "fingernails",
  Q_H_CITY = "city",
  Q_H_PROCEDURE = "procedure",
  Q_H_OPERATIVE_CONCERN = "operative-concern",
  Q_H_SEX_DRIVE = "sex-drive",
  Q_H_IMPROVE_SEX = "improve-sex",
  Q_H_LIBIDO_SIMPTOMS = "libido-simptoms",
  Q_H_ERECTILE_SIMPTOMS = "erectile-simptoms",
  Q_H_FERTILITY = "fertility",
  Q_H_ALCOHOLIC = "alcoholic",
  Q_H_ANEMIA = "anemia",
  Q_H_COLD = "colds",
  Q_H_CONCENTRATING = "concentrating",
  Q_H_ENERGY = "energy",
  Q_H_SLEEP_QUALITY = "sleep-quality",
  Q_H_SEEING = "seeing",
  Q_H_DRY_EYES = "dry-eyes",
  Q_H_STRESS = "stress",
  Q_H_ATHLETE = "athlete",
  Q_H_SWEAT = "sweat",
  Q_H_WORKOUT = "workout",
  Q_H_CRAMPS = "cramps",
  Q_H_MUSCLE_INJURIES = "muscle-injuries",
  Q_H_INJURIES = "injuries",
  Q_L_ACTIVITY = "activity",
  Q_L_MEAT = "meat",
  Q_L_FISH = "fish",
  Q_L_DIARY = "diary",
  Q_L_FRUIT = "fruit",
  Q_L_EGGS = "eggs",
  Q_L_LEGUMES = "legumes",
  Q_L_DIET = "diet",
  Q_L_ALLERGIES = "allergic",
  Q_L_WHAT_MEDS = "what-meds",
  Q_L_MEDICAL_COND = "medical-condition",
  Q_L_CANCER = "cancer",
  Q_L_SMOKE = "smoke",
  Q_L_CAFFEINATE = "caffeinated-drinks",
  Q_L_FULL_NAME = "full-name",
  Q_L_EMAIL = "e-mail",
  Q_L_NAME_BOTTLE = "name-bottle",
  LOADING = "loading",
  ORDER_RECIPE = "order-recipe",
  THANK_YOU = "thank-you/:secret?",
  PAY = "pay",
  P_INFORMATION = "information",
  P_PAYMENT = "payment",
}

export const RouteKeyToPathMapper: {
  [key in RouteKey]: RoutePath;
} = {
  "section-intro": RoutePath.SECTION_INTRO,
  "section-basics": RoutePath.SECTION_BASICS,
  "section-health": RoutePath.SECTION_HEALTH,
  "section-lifestyle": RoutePath.SECTION_LIFESTYLE,
  "b-dob": RoutePath.Q_B_DOB,
  "b-under-18": RoutePath.Q_B_UNDER_18,
  "b-height": RoutePath.Q_B_HEIGHT,
  "b-weight": RoutePath.Q_B_WEIGHT,
  "b-pre-post-natal": RoutePath.Q_B_PRE_POST_NATAL_HEALTH,
  "b-pregnant-or-planning": RoutePath.Q_B_PREGNANT_OR_PLANNING,
  "b-which-describes": RoutePath.Q_B_WHICH_BEST_DESCRIBES,
  "b-pregnancy-weeks": RoutePath.Q_B_PREGNANCY_WEEKS,
  "b-ask-reproductive": RoutePath.Q_B_ASK_REPRODUCTIVE,
  "b-menstrual": RoutePath.Q_B_MENSTRUAL,
  "b-premenstrual": RoutePath.Q_B_PREMESNSTRUAL,
  "b-menopause": RoutePath.Q_B_MENOPAUSE,
  "h-gender": RoutePath.Q_H_GENDER,
  "h-concerns": RoutePath.Q_H_CONCERNS,
  "h-brain-fog": RoutePath.Q_H_BRAIN_FOG,
  "h-cognitive": RoutePath.Q_H_COGNITIVE,
  "h-blood-pressure": RoutePath.Q_H_BLOOD_PRESSURE,
  "h-edema": RoutePath.Q_H_EDEMA,
  "h-anxiety": RoutePath.Q_H_ANXIETY,
  "h-mood": RoutePath.Q_H_MOOD,
  "h-depression": RoutePath.Q_H_DEPRESSION,
  "h-asleep": RoutePath.Q_H_ASLEEP,
  "h-panic": RoutePath.Q_H_PANIC,
  "h-fitness-goal": RoutePath.Q_H_FITNESS_GOAL,
  "h-physical-activity": RoutePath.Q_H_PHYSICAL_ACTIVITY,
  "h-mental-worn-out": RoutePath.Q_H_MENTAL_WORN_OUT,
  "h-joint-pain": RoutePath.Q_H_JOINT_PAIN,
  "h-broken": RoutePath.Q_H_BROKEN,
  "h-skin-type": RoutePath.Q_H_SKIN_TYPE,
  "h-skin-issues": RoutePath.Q_H_SKIN_ISSUES,
  "h-skin-breakouts": RoutePath.Q_H_SKIN_BREAKOUTS,
  "h-skin-flaky": RoutePath.Q_H_SKIN_FLAKY,
  "h-hair": RoutePath.Q_H_HAIR,
  "h-fingernails": RoutePath.Q_H_FINGERNAILS,
  "h-city": RoutePath.Q_H_CITY,
  "h-procedure": RoutePath.Q_H_PROCEDURE,
  "h-operative-concern": RoutePath.Q_H_OPERATIVE_CONCERN,
  "h-sex-drive": RoutePath.Q_H_SEX_DRIVE,
  "h-improve-sex": RoutePath.Q_H_IMPROVE_SEX,
  "h-libido-simptoms": RoutePath.Q_H_LIBIDO_SIMPTOMS,
  "h-erectile-simptoms": RoutePath.Q_H_ERECTILE_SIMPTOMS,
  "h-fertility": RoutePath.Q_H_FERTILITY,
  "h-alcoholic": RoutePath.Q_H_ALCOHOLIC,
  "h-anemia": RoutePath.Q_H_ANEMIA,
  "h-colds": RoutePath.Q_H_COLD,
  "h-concentrating": RoutePath.Q_H_CONCENTRATING,
  "h-energy": RoutePath.Q_H_ENERGY,
  "h-sleep-quality": RoutePath.Q_H_SLEEP_QUALITY,
  "h-seeing": RoutePath.Q_H_SEEING,
  "h-dry-eyes": RoutePath.Q_H_DRY_EYES,
  "h-stress": RoutePath.Q_H_STRESS,
  "h-athlete": RoutePath.Q_H_ATHLETE,
  "h-sweat": RoutePath.Q_H_SWEAT,
  "h-workout": RoutePath.Q_H_WORKOUT,
  "h-cramps": RoutePath.Q_H_CRAMPS,
  "h-muscle-injuries": RoutePath.Q_H_MUSCLE_INJURIES,
  "h-injuries": RoutePath.Q_H_INJURIES,
  "l-activity": RoutePath.Q_L_ACTIVITY,
  "l-meat": RoutePath.Q_L_MEAT,
  "l-fish": RoutePath.Q_L_FISH,
  "l-diary": RoutePath.Q_L_DIARY,
  "l-fruit": RoutePath.Q_L_FRUIT,
  "l-eggs": RoutePath.Q_L_EGGS,
  "l-legumes": RoutePath.Q_L_LEGUMES,
  "l-diet": RoutePath.Q_L_DIET,
  "l-allergic": RoutePath.Q_L_ALLERGIES,
  "l-meds": RoutePath.Q_L_WHAT_MEDS,
  "l-medical-cond": RoutePath.Q_L_MEDICAL_COND,
  "l-cancer": RoutePath.Q_L_CANCER,
  "l-smoke": RoutePath.Q_L_SMOKE,
  "l-caffeinate": RoutePath.Q_L_CAFFEINATE,
  "l-full-name": RoutePath.Q_L_FULL_NAME,
  "l-e-mail": RoutePath.Q_L_EMAIL,
  "l-name-bottle": RoutePath.Q_L_NAME_BOTTLE,
  loading: RoutePath.LOADING,
  "order-recipe": RoutePath.ORDER_RECIPE,
  "thank-you": RoutePath.THANK_YOU,
  "pay-information": RoutePath.P_INFORMATION,
  "pay-payment": RoutePath.P_PAYMENT,
};

const lastPageFromStorage = () => {
  const lastPageJson = localStorage.getItem(LocalStorageKeys.lastPage);
  if (!lastPageJson) return [];

  const lastPage = JSON.parse(lastPageJson);
  const sKey = lastPage.sKey as SectionKey;
  const qkey = lastPage.qKey as QuestionKey;
  if (lastPage.qKey && RouteKeyToPathMapper[qkey]) {
    // question screen
    return [
      {
        path: RouteKeyToPathMapper[qkey],
        component: QuestionActive,
        name: qkey,
        meta: { qKey: qkey, sKey: sKey },
      },
    ];
  }
  // section screen
  return [
    {
      path: RouteKeyToPathMapper[sKey],
      component: () => import("@/views/SectionComp.vue"),
      name: sKey,
      props: { sectionNr: sectionToNumberStep[sKey] },
    },
  ];
};

export const routes = [
  {
    path: "/",
    name: "main",
    component: MainView,
    redirect: <{ name: RouteKey }>{ name: "section-intro" },
    beforeEnter: [
      initializeCheckoutVersion,
      initializeCheckoutShopifyVersion,
      initializeFormulaPresentation,
      setDataFromStorage,
      initializeLanguage,
      initializeCountryCodes,
      setIPLocationCountry,
      adaptQuestionsType,
      setHealthGoalsFromUrl,
    ],
    children: [
      ...lastPageFromStorage(),
      {
        path: RoutePath.SECTION_INTRO,
        name: SectionKey.INTRO,
        component: () => import("@/views/SectionComp.vue"),
        props: { sectionNr: 0 },
      },
      {
        path: RoutePath.Q_B_UNDER_18,
        name: NoneQuestionKey.UNDER_18,
        component: () => import("@/views/intro/Under18Comp.vue"),
      },
      {
        path: RoutePath.LOADING,
        name: OtherRouteKey.LOADING,
        component: () => import("@/views/LoadingView.vue"),
        beforeEnter: saveQuestionnaireToLS,
      },
      {
        path: RoutePath.ORDER_RECIPE,
        name: OtherRouteKey.ORDER_RECIPE,
        component: () => import("@/views/OrderRecipe.vue"),
        beforeEnter: [
          resetStoreToIPStore,
          loadContactFromCrm,
          checkForSupplementsFormula,
          resetDiscount,
          resetReferral,
        ],
      },
      {
        path: RoutePath.THANK_YOU,
        name: OtherRouteKey.THANK_YOU,
        component: () => import("@/views/ThankYouV2.vue"),
        beforeEnter: [checkThankYouQueryParams, checkThankYouLocalStorageData],
      },
      {
        path: RoutePath.PAY,
        component: () => import("@/views/payment/PaymentView.vue"),
        beforeEnter: [
          reloadIfFromThankYou,
          checkForSupplementsFormula,
          setDiscount,
          setReferral,
          setCampaignDiscount,
          initializeGoogleServices,
        ],
        redirect: { name: PaymentKey.INFORMATION },
        children: [
          {
            path: RoutePath.P_INFORMATION,
            name: PaymentKey.INFORMATION,
            component: () => import("@/components/payment/PayInformationView.vue"),
            beforeEnter: [redirectToOrderRecipeIfNotAllowed],
          },
          {
            path: RoutePath.P_PAYMENT,
            name: PaymentKey.PAYMENT,
            component: () => import("@/components/payment/PayPaymentView.vue"),
            beforeEnter: [redirectToOrderRecipeIfNotAllowed, informationMustBeFilled],
          },
        ],
      },
      {
        path: "/:catchAll(.*)*",
        redirect: { name: SectionKey.INTRO },
      },
    ],
  },
];
