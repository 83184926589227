import { computed } from "vue";
import { LegalLinks } from "@/utils/constants";
import { useMainStore } from "@/stores/main";

export function useLegal() {
  const mainStore = useMainStore();

  const linkTermsAndCond = computed<string>(() => {
    return LegalLinks[mainStore.store].terms(mainStore.locale);
  });

  const linkImprint = computed<string>(() => {
    return LegalLinks[mainStore.store].imprint(mainStore.locale);
  });

  const linkPrivacy = computed<string>(() => {
    return LegalLinks[mainStore.store].privacy(mainStore.locale);
  });

  return { linkTermsAndCond, linkImprint, linkPrivacy };
}
